
import { Options, Vue } from 'vue-class-component';
import { ChallengeClient } from '@/services/Services';
import EditStageModal from '../modal/editStageModal.vue';
import AreYouSureModal from '@/modals/areYouSureModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ChallengeConfiguration extends Vue {

    stages: OM.StageVM[] = [];

    created() {
        this.init();
    }

    init() {
        ChallengeClient.getAllStages()
        .then(x => {
            this.stages = x;
        })
    }

    getText(notTranslatedText: OM.LocalizedValue) {
        return this.$localizationService.getTextFromValues(notTranslatedText.values);
    }

    editStage(stage: OM.StageVM) {
        var model = new OM.StageVM();

        if(stage != null) {
            model.identifier = stage.identifier;
            model.title = stage.title;
            model.hint = stage.hint;
        }

        this.$opModal.show(EditStageModal, {
            model: model,
            callback: (updatedModel: OM.StageVM, file: File) => {
                ChallengeClient.editStage(updatedModel)
                .then(x => {
                    if(file != null || (file == null && !updatedModel.imageUrl)) {
                        ChallengeClient.updateStageImage(x, file)
                        .then(x => {
                            this.$opModal.closeLast();
                            this.init();
                        })
                    }
                    else {
                        this.$opModal.closeLast();
                        this.init();
                    }
                })
            }
        })
    }
    deleteStage(identifier: string) {
        this.$opModal.show(AreYouSureModal, {
            infoText: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareSfida", "Sei sicuro di voler eliminare l'intera sfida?"),
            denyText: this.$localizationService.getLocalizedValue("backoffice_Annulla", "Annulla"),
            successText: this.$localizationService.getLocalizedValue("backoffice_Conferma", "Conferma"),
            denyCallback: () => {
                this.$opModal.closeLast();
            },
            successCallback: () => {
                ChallengeClient.deleteStage(identifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
