
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ChallengeClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditStageModal extends Vue {

    @Prop({
        default: () => new OM.StageVM()
    }) model: OM.StageVM;
    localModel: OM.StageVM = new OM.StageVM();

    @Prop() callback: any;

    localFile: File = null;

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.model));
        if(this.localModel.identifier) {
            ChallengeClient.getImagePath(this.localModel.identifier, null)
            .then(x => {
                this.localModel.imageUrl = x;
            })
        }
    }

    save() {
        this.callback(this.localModel, this.localFile);
    }

}
